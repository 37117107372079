html, body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
}

body {
  background: #7E9CAE;
  background: url("../assets/background.jpg") no-repeat center center fixed;
  background-size: cover;
}